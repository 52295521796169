<template>
  <div class="content-wrapper">
    <bo-page-title />
    <div class="content pt-0">

      <b-card no-body>
        <b-card-header class="mb-0 pb-0">
          <b-row>
            <div class="col-md-auto">
              <h5 class="font-weight-semibold">Setting Finger Print CPPT</h5>
            </div>
          </b-row>
        </b-card-header>
        <b-card-body class="p-2">
          <div class="row">
            <div class="col-md-12">
              <label for="">Apakah anda menyalakan konifgurasi fingerprint untuk data cppt</label>
              <b-form-radio-group
                :options="Config.mr.yesNoOptV2"
                v-model="settingDataCPPT"
              />
              <button type="button" @click="updateSettings()" class="btn btn-success btn-labeled btn-labeled-left mt-2"><b><i class="icon-checkmark"></i></b> Simpan Perubahan</button> 
            </div>
          </div>
        </b-card-body>
      </b-card>

      <b-card no-body>
        <b-card-header>
          <b-row>
            <div class="col-md-auto">
              <div class="form-group mb-0">
                <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip"
                  class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.left><i class="icon-spinner11"></i></button>
              </div>
            </div>
            <b-col lg="4">
              <SearchInput :value.sync="filter.search" @search="doFill" />
            </b-col>
            <div class="col-md" v-if="moduleRole('add')">
              <div class="text-right">
                <a href="javascript:;"
                  @click="doOpen(null)"
                  class="btn bg-primary btn-labeled btn-labeled-left"><b><i class="icon-add"></i></b> Tambah Data</a>
              </div>
            </div>
          </b-row>

        </b-card-header>
        <b-card-body class="p-0">
          <b-table
            :fields="fields"
            :items="dataList||[]"
            :per-page="perPage"
            :primary-key="idKey"
            :busy="!dataList"
            responsive
            show-empty
            striped
          >
            <template #empty>
              <div class="text-center">
                <h4 align="center"><span v-if="filter.status || filter.search">Hasil pencarian tidak ditemukan</span>
                <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
              </div>
            </template>
            <template #table-busy>
              <div class="skeletal-comp mb-2"></div>
              <div class="skeletal-comp mb-2"></div>
              <div class="skeletal-comp"></div>
            </template>
            <template #cell(number)="v">
              {{(data.per_page*(data.current_page-1))+v.index+1}}
            </template>
            
            <template
              #cell(last_update)="data"
            >
              <span v-if="data.item.aufd_updated_date">{{data.item.aufd_updated_date | moment("DD MMMM YYYY, HH:mm")}}</span>
              <span v-else>{{data.item.aufd_created_date | moment("DD MMMM YYYY, HH:mm")}}</span>
              
            </template>
            <template
              #cell(action)="data"
            >
            
              <div class="list-icons">
                <a href="javascript:;" class="list-icons-item"
                  v-if="moduleRole('edit')"
                  @click="doOpen(data.item.aufd_user_id, data.item)"
                  data-toggle="tooltip" data-placement="top" title="Edit"><i
                    class="icon-pencil"></i></a>

                <a href="javascript:;" class="list-icons-item"
                  v-if="moduleRole('delete')"
                  @click="doDelete(data.index, data.item)"
                  data-toggle="tooltip" data-placement="top" title="Delete"><i
                    class="icon-bin"></i></a>
              </div>
              <span v-if="!(moduleRole('delete') || moduleRole('edit'))">-</span>
            </template>
          </b-table>
        </b-card-body>
        <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">        
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </b-card-footer>
      </b-card>
    </div>

		<validation-observer ref="VFormEnroll">
    <b-modal v-model="openAdd" hide-footer :title="'Enroll Data User'" size="md">
      <div class="row" v-if="!isEnroll">
        <div class="col-12">
          <b-form-group>
            <label>Pilih Dokter<span class="text-danger mr5">*</span></label>
            <v-select :disabled="dataRow.aufd_id || isEnroll" placeholder="Pilih Dokter" v-model="dataRow.id"
            :options="mDokter" @input="changeDataDokter($event)" label="text" :clearable="true"
            :reduce="v=>v.value"></v-select>
            
            <VValidate 
              name="Dokter" 
              v-model="dataRow.id" 
              :rules="{required:1}"
            />

            <small class="text-info">*Pastikan Data Dokter yang dipilih sudah benar untuk keperluan enrollment, data yang sudah dipilih tidak bisa diedit kembali</small>
          </b-form-group>
        
          <b-form-group>
            <label>NIK<span class="text-danger mr5">*</span></label>
            <b-form-input :disabled="isEnroll" type="text" class="form-control" v-model="dataRow.bu_nik" :formatter="numberOnly" placeholder="e.g Administrator"/>
            <VValidate 
              name="NIK" 
              v-model="dataRow.bu_nik" 
              :rules="{required:1, min:16, max: 16}"
            />
          </b-form-group>

          <button type="submit" @click="updateUsers()" class="btn btn-success btn-labeled btn-labeled-left"><b><i class="icon-checkmark"></i></b> Enroll Data</button>          
        </div>
      </div>

      <div class="row mt-2" v-else>
        <div class="col-12">
          <p>
            Proses Enrollment Sedang Berlangsung ...
          </p>
          <b class="text-warning text-center d-block mt-2"><i style="font-size: 70px;" class="icon-notification2"></i></b>
          <p class="text-info text-center mt-2" v-if="(leftFinger.length < 4 && !rightFinger.length)">Mohon taruh ibu jari kiri anda pada alat finger print</p>
          <p class="text-info text-center mt-2" v-else-if="(rightFinger.length < 4 && leftFinger.length == 4)">Mohon taruh ibu jari kanan anda pada alat finger print</p>
          <p class="text-info text-center mt-2" v-else>Enrollment selesai, silakan submit untuk melanjutkan</p>
        </div>
        <div class="col-12">
          <table class="table table-sm table-bordered">
            <tr>
              <th colspan="5">Status EnrollMent</th>
            </tr>
            <tr>
              <td>Ibu Jari Kiri</td>
              <td :class="(leftFinger.length >= 1) ? 'bg-success' : 'bg-danger'"></td>
              <td :class="(leftFinger.length >= 2) ? 'bg-success' : 'bg-danger'"></td>
              <td :class="(leftFinger.length >= 3) ? 'bg-success' : 'bg-danger'"></td>
              <td :class="(leftFinger.length >= 4) ? 'bg-success' : 'bg-danger'"></td>
            </tr>
            <tr>
              <td>Ibu Jari Kanan</td>
              <td :class="(rightFinger.length >= 1) ? 'bg-success' : 'bg-danger'"></td>
              <td :class="(rightFinger.length >= 2) ? 'bg-success' : 'bg-danger'"></td>
              <td :class="(rightFinger.length >= 3) ? 'bg-success' : 'bg-danger'"></td>
              <td :class="(rightFinger.length >= 4) ? 'bg-success' : 'bg-danger'"></td>
            </tr>
          </table>
        </div>
        <div class="col-12 mt-3 text-right" v-if="(leftFinger.length >= 4 && rightFinger.length >= 4)">
          <button type="submit" @click="enrollData()" class="btn btn-success btn-labeled btn-labeled-left"><b><i class="icon-checkmark"></i></b> Simpan Data</button>  
        </div>
      </div>
    </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'
const _ = global._

export default {
  extends: GlobalVue,
  data() {
    return {
      idKey:'aufd_id',
      statusKey:'aufd_is_active',
      fields: [
        {
          key: 'number',
          label: '#',
        },
        {
          key: 'bu_full_name',
          label: 'Nama Dokter',
        },
        {
          key: 'last_update',
          label: 'Last Update',
        },
        {
          key: 'action',
          label: 'Aksi',
        },
      ],
      openAdd: false,
      dataRow: {
        bu_fullname: null,
        bu_nik: null
      },
      mDokter: [],
      isEnroll: false,
      leftFinger: [],
      rightFinger: [],
      settingDataCPPT: 'N'
    }
  },
  computed:{
    passToSub(){
      return _.assign(this.passToSubComp||{})
    }
  },
  mounted(){
    this.apiGet()
    if(this.$route.params.pageSlug){
      this.validateModuleRoleCrud()
    }
  },
  methods: {
    doFill(){
      this.doFilter()
    },
    doRefreshData(){
      this.apiGet()
      window.scrollTo(0,0)
    },
    doOpen(id,data={}){
      this.$set(this,'dataRow', {
        id : id||null,
        aufd_id : data.aufd_id||null,
        bu_fullname : data.bu_fullname||null,
        bu_nik : data.bu_nik||null,
      })
      this.openAdd = true
    },
    changeDataDokter(e){
      let index = (this.mDokter||[]).findIndex(x => x.value == e)
      if(index != -1){
        this.dataRow.bu_nik = this.mDokter[index]['nik']
      }
    },
    updateUsers(){
      this.$refs['VFormEnroll'].validate().then(success => {
        if(success){
          this.loadingOverlay = true
          let postData = {
            id: this.dataRow.id,
            type : "update-user",
            nik : this.dataRow.bu_nik
          }

          Gen.apiRest(
            "/do/"+'FingerPrint', 
            {
                data: postData
            }, "POST"
          ).then(res=>{
            this.loadingOverlay = false
            this.isEnroll = true
            this.initAll()
          })
        }
      })
    },
    enrollData(){
      this.$swal({
        icon: 'warning',
        title: 'Apakah Anda Yakin akan menyimpan data ini?',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak, kembali'
      }).then(result => {
        if (result.value) {
          this.loadingOverlay = true
          let id = this.dataRow.id
          let leftFinger = this.leftFinger
          let rightFinger = this.rightFinger
          let data = {id, leftFinger, rightFinger}
          
          let postData = {
            data : data,
            id: this.dataRow.id,
            type : "enroll"
          }

          Gen.apiRest(
            "/do/"+'FingerPrint', 
            {
                data: postData
            }, "POST"
          ).then(res=>{
              this.loadingOverlay = false
              let resp = res.data
              resp.statusType = 200
              this.$swal({
                title: resp.message,
                icon: resp.status,
                confirmButtonText: 'Ok',
                allowOutsideClick: false,
                allowEscapeKey: false
              }).then(result => {
                if (result.value) {
                  this.resetData()
                  this.apiGet()
                }
              })
          }).catch(err => {
            this.loadingOverlay = false
            if (err) {
              err.statusType = err.status
              err.status = "error"
              err.title = err.response?.data?.title
              err.message = err.response?.data?.message
              err.messageError = err.message
            }
            this.doSetAlertForm(err)
          })
        }
      })
    },
    resetData(){
      this.openAdd = false                
      this.isEnroll = false
      this.leftFinger = []
      this.rightFinger = []
    },
    initAll(){
      let self = this
      let currentFormat = window.Fingerprint.SampleFormat.Intermediate
    
      let FingerprintSdkTest = (function () {
        function FingerprintSdkTest() {
            this.operationToRestart = null
            this.acquisitionStarted = false
            // instantiating the fingerprint sdk here
            this.sdk = new window.Fingerprint.WebApi
						console.log(this.sdk)

            this.sdk.onDeviceConnected = function (e) {
                console.log("connect")
            }
            this.sdk.onDeviceDisconnected = function (e) {
                // Detects if device gets disconnected - provides deviceUid of disconnected device
                console.log("disconnect")
            }
            this.sdk.onCommunicationFailed = function (e) {
                // Detects if there is a failure in communicating with U.R.U web SDK
                console.log("failure")
            }
            this.sdk.onSamplesAcquired = function (s) {
                self.storeSample(s)
            }
            this.sdk.onQualityReported = function (e) {
                // Quality of sample acquired - Function triggered on every sample acquired
                //document.getElementById("qualityInputBox").value = window.Fingerprint.QualityCode[(e.quality)]
            }
        }

        // this is were finger print capture takes place
        FingerprintSdkTest.prototype.startCapture = function () {
            if (this.acquisitionStarted) // Monitoring if already started capturing
                return
            let _instance = this
            this.operationToRestart = this.startCapture
            this.sdk.startAcquisition(currentFormat, "").then(function () {
                _instance.acquisitionStarted = true
            }, function (error) {
              console.log(error)
            })
        }
        
        FingerprintSdkTest.prototype.stopCapture = function () {
            if (!this.acquisitionStarted) //Monitor if already stopped capturing
                return
            let _instance = this
            this.sdk.stopAcquisition().then(function () {
                _instance.acquisitionStarted = false
            }, function (error) {
              console.log(error)
            })
        }

        FingerprintSdkTest.prototype.getInfo = function () {
            return this.sdk.enumerateDevices()
        }

        FingerprintSdkTest.prototype.getDeviceInfoWithID = function (uid) {
            return  this.sdk.getDeviceInfo(uid)
        }
        return FingerprintSdkTest
      })()

      class Reader{
          constructor(){
              this.reader = new FingerprintSdkTest()
              this.selectFieldID = null
              this.currentStatusField = null
              /**
               * @type {Hand}
               */
              this.currentHand = null
          }

          readerSelectField(selectFieldID){
              this.selectFieldID = selectFieldID
          }

          setStatusField(statusFieldID){
              this.currentStatusField = statusFieldID
          }

          displayReader(){
              let readers = this.reader.getInfo()  // grab available readers here
              let id = this.selectFieldID
              let selectField = document.getElementById(id)
              selectField.innerHTML = `<option>Select Fingerprint Reader</option>`
              readers.then(function(availableReaders){  // when promise is fulfilled
                  if(availableReaders.length > 0){
                      for(let reader of availableReaders){
                          selectField.innerHTML += `<option value="${reader}" selected>${reader}</option>`
                      }
                  }
                  else{
                      console.log("Please Connect the Fingerprint Reader")
                  }
              })
          }
      }

      class Hand{
        constructor(){
          this.id = 0
        }
      }

      let myReader = new Reader()

      myReader.currentHand = new Hand()
      myReader.reader.startCapture()
    },
    storeSample(sample){
      let samples = JSON.parse(sample.samples)
      let sampleData = samples[0].Data
      
      // enroll
      if(this.leftFinger.length < 4 && !this.rightFinger.length){
        this.leftFinger.push(sampleData)
      }else if(this.rightFinger.length < 4 && this.leftFinger.length == 4){
        this.rightFinger.push(sampleData)
      }

      // verify
      this.verifyFinger = sampleData

      console.log("ini samples Data",sampleData)
      console.log(this.leftFinger)
      console.log(this.rightFinger)
    },
    updateSettings(){
      
      this.$swal({
        icon: 'warning',
        title: 'Apakah Anda Yakin akan menyimpan data ini? Pastikan Fitur yang akan digunakan telah dikoordinasikan kepada unit terkait',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak, kembali'
      }).then(result => {
        if (result.value) {
          this.loadingOverlay = true
          let postData = {
            type : "update-settings",
            value : this.settingDataCPPT
          }

          Gen.apiRest(
            "/do/"+'FingerPrint', 
            {
                data: postData
            }, "POST"
          ).then(res=>{
            this.loadingOverlay = false
            let resp = res.data
            resp.statusType = 200
            this.$swal({
              title: resp.message,
              icon: resp.status,
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(result => {
              if (result.value) {
                this.resetData()
                this.apiGet()
              }
            })
          })
        }
      })
      
    }
  },
  watch:{
    $route(){
      this.apiGet()
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    },
    'openAdd'(v){
      if(!v){
        this.resetData()
      }
    }
  }
}
</script>